//API
export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_ERROR = "FETCH_ERROR";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const TOGGLE_APP_DRAWER = "TOGGLE_APP_DRAWER";
export const UPDATING_CONTENT = "UPDATING_CONTENT";
export const ERROR = "ERROR";

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = "TOGGLE_NAV_COLLAPSED";
export const SET_INITIAL_PATH = "SET_INITIAL_PATH";

//AUTH0
export const UPDATE_AUTH_USER = "UPDATE_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const SIGNOUT_AUTH_SUCCESS = "SIGNOUT_AUTH_SUCCESS";
// get-all-sub-plan
export const GET_ALL_SUB_PLAN = "GET_ALL_SUB_PLAN";
export const GET_ALL_SUB_PLAN_ERRORS = "GET_ALL_SUB_PLAN_ERRORS-errors";
// create-sub-plan
export const CREATE_SUB_PLAN = "CREATE_SUB_PLAN";
export const CREATE_SUB_PLAN_ERROR = "CREATE_SUB_PLAN_ERROR-error";
// delete sub - plan
export const DELETE_SUB_PLAN = "DELETE_SUB_PLAN-plan";
export const DELETE_SUB_PLAN_ERROR = "DELETE_SUB_PLAN_ERROR";
// update sub plan
export const UPDATE_SUB_PLAN = "UPDATE_SUB_PLAN-plan";
export const UPDATE_SUB_PLAN_ERROR = "UPDATE_SUB_PLAN_ERROR";
// ebook
export const GET_EBOOK = "GET_EBOOK";
export const GET_EBOOK_ERROR = "GET_EBOOK_ERROR-error";
// create ebook
export const CREATE_EBOOK = "CREATE_EBOOK";
export const CREATE_EBOOK_ERROR = "CREATE_EBOOK_ERROR-error";
export const UPDATE_EBOOK = "UPDATE_EBOOK";
export const UPDATE_EBOOK_ERROR = "UPDATE_EBOOK_ERROR-error";
// delete ebook
export const DELETE_EBOOK = "DELETE_EBOOK";
export const DELETE_EBOOK_ERROR = "DELETE_EBOOK_ERROR";
// getDailyQuotes
export const GET_QUOTES = "GET_QUOTES";
export const GET_QUOTES_ERROS = "GET_QUOTES_ERROS";
// create Daily Quotes
export const CREATE_QUOTES = "CREATE_QUOTES";
export const CREATE_QUOTES_ERROR = "CREATE_QUOTES_ERROR";
// delete daily quotes
export const DELETE_QUOTES = "DELETE_QUOTES";
export const DELETE_QUOTES_ERROR = "DELETE_QUOTES_ERROR";
export const UPDATE_QUOTES = "UPDATE_QUOTES";
export const UPDATE_QUOTES_ERROR = "UPDATE_QUOTES_ERROR";
// get subcriber
export const GET_SUBCRIBER = "GET_SUBCRIBER";
export const GET_SUBCRIBER_ERROR = "GET_SUBCRIBER_ERROR";
// create subcriber
export const CREATE_SUBCRIBER = "CREATE_SUBCRIBER";
export const CREATE_SUBCRIBER_ERROR = "CREATE_SUBCRIBER_ERROR";

// ====================================
//  MODULE : SURVEY
// ====================================
export const GET_SURVEY = "GET_SURVEY";
export const GET_SURVEY_ERROR = "GET_SURVEY_ERROR";
export const CREATE_SURVEY = "CREATE_SURVEY";
export const CREATE_SURVEY_ERROR = "CREATE_SURVEY_ERROR";
export const UPDATE_SURVEY = "UPDATE_SURVEY";
export const UPDATE_SURVEY_ERROR = "UPDATE_SURVEY_ERROR";
export const DELETE_SURVEY = "DELETE_SURVEY";
export const DELETE_SURVEY_ERROR = "DELETE_SURVEY_ERROR";
export const GET_SURVEY_FEEDBACK = "GET_SURVEY_FEEDBACK";
export const GET_SURVEY_FEEDBACK_ERROR = "GET_SURVEY_FEEDBACK_ERROR";
export const GET_SURVEY_QUESTION = "GET_SURVEY_QUESTION";
export const GET_SURVEY_QUESTION_ERROR = "GET_SURVEY_QUESTION_ERROR";
export const CREATE_SURVEY_QUESTION = "CREATE_SURVEY_QUESTION";
export const CREATE_SURVEY_QUESTION_ERROR = "CREATE_SURVEY_QUESTION_ERROR";
export const UPDATE_SURVEY_QUESTION = "UPDATE_SURVEY_QUESTION";
export const UPDATE_SURVEY_QUESTION_ERROR = "UPDATE_SURVEY_QUESTION_ERROR";
export const DELETE_SURVEY_QUESTION = "DELETE_SURVEY_QUESTION";
export const DELETE_SURVEY_QUESTION_ERROR = "DELETE_SURVEY_QUESTION_ERROR";

// Ashramwashi Portal
export const GET_Ashramwashi_Events = "GET_Ashramwashi_Events";
export const GET_Ashramwashi_Events_ERROR = "GET_Ashramwashi_Events_ERROR";
export const CREATE_Ashramwashi_Events = "CREATE_Ashramwashi_Events";
export const CREATE_Ashramwashi_Events_ERROR =
    "CREATE_Ashramwashi_Events_ERROR";
export const UPDATE_Ashramwashi_Events = "UPDATE_Ashramwashi_Events";
export const UPDATE_Ashramwashi_Events_ERROR =
    "UPDATE_Ashramwashi_Events_ERROR";
export const DELETE_Ashramwashi_Events = "DELETE_Ashramwashi_Events";
export const DELETE_Ashramwashi_Events_ERROR =
    "DELETE_Ashramwashi_Events_ERROR";
// Quiz
export const GET_QUIZ = "GET_QUIZ";
export const GET_QUIZ_ERROR = "GET_QUIZ_ERROR";
export const CREATE_QUIZ = "CREATE_QUIZ";
export const CREATE_QUIZ_ERROR = "CREATE_QUIZ_ERROR";
export const UPDATE_QUIZ = "UPDATE_QUIZ";
export const UPDATE_QUIZ_ERROR = "UPDATE_QUIZ_ERROR";
export const DELETE_QUIZ = "DELETE_QUIZ";
export const DELETE_QUIZ_ERROR = "DELETE_QUIZ_ERROR";
// Quiz Question
export const GET_QUIZ_QUES = "GET_QUIZ_QUES";
export const GET_QUIZ_QUES_ERROR = "GET_QUIZ_QUES_ERROR";
export const CREATE_QUIZ_QUES = "CREATE_QUIZ_QUES";
export const CREATE_QUIZ_QUES_ERROR = "CREATE_QUIZ_QUES_ERROR";
export const UPDATE_QUIZ_QUES = "UPDATE_QUIZ_QUES";
export const UPDATE_QUIZ_QUES_ERROR = "UPDATE_QUIZ_QUES_ERROR";
export const DELETE_QUIZ_QUES = "DELETE_QUIZ_QUES";
export const DELETE_QUIZ_QUES_ERROR = "DELETE_QUIZ_QUES_ERROR";
export const GET_FEEDBACK = "GET_FEEDBACK";
export const GET_FEEDBACK_ERROR = "GET_FEEDBACK_ERROR-error";
// courier
export const GET_ALL_ORDERS = "GET_ALL_ORDERS";
export const GET_ORDER_DETAILS = "GET_ORDER_DETAILS";
export const GET_ALL_ORDERS_ERROR = "GET_ALL_ORDERS_ERROR";
export const CREATE_COURIER = "CREATE_COURIER";
export const CREATE_COURIER_ERROR = "CREATE_COURIER_ERROR";
export const UPDATE_COURIER = "UPDATE_COURIER";
export const UPDATE_COURIER_ERROR = "UPDATE_COURIER_ERROR";
// blog
export const GET_BLOG = "GET_BLOG";
export const CREATE_BLOG = "CREATE_BLOG";
export const CREATE_BLOG_ERROR = "CREATE_BLOG_ERROR";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const UPDATE_BLOG_ERROR = "UPDATE_BLOG_ERROR";
export const DELETE_BLOG = "DELETE_BLOG";
export const DELETE_BLOG_ERROR = "DELETE_BLOG_ERROR";
// blog comments
export const GET_BLOG_COMMENTS = "GET_BLOG_COMMENTS";
export const GET_BLOG_COMMENTS_ERROR = "GET_BLOG_COMMENTS_ERROR";
export const CREATE_BLOG_COMMENTS = "CREATE_BLOG_COMMENTS";
export const CREATE_BLOG_COMMENTS_ERROR = "CREATE_BLOG_COMMENTS_ERROR";
export const DELETE_BLOG_COMMENTS = "DELETE_BLOG_COMMENTS";
export const DELETE_BLOG_COMMENTS_ERROR = "DELETE_BLOG_COMMENTS_ERROR";
// media Video
export const GET_MEDIA_VIDEO = "GET_MEDIA_VIDEO";
export const GET_MEDIA_VIDEO_ERROR = "GET_MEDIA_VIDEO_ERROR ";
export const UPDATE_MEDIA_VIDEO = "UPDATE_MEDIA_VIDEO";
export const UPDATE_MEDIA_VIDEO_ERROR = "UPDATE_MEDIA_VIDEO_ERROR";
export const CREATE_MEDIA_VIDEO = "CREATE_MEDIA_VIDEO";
export const CREATE_MEDIA_VIDEO_ERROR = "CREATE_MEDIA_VIDEO_ERROR";
export const DELETE_MEDIA_VIDEO = "DELETE_MEDIA_VIDEO";
export const DELETE_MEDIA_VIDEO_ERROR = "DELETE_MEDIA_VIDEO_ERROR";
// media audio
export const GET_MEDIA_AUDIO = "GET_MEDIA_AUDIO";
export const GET_MEDIA_AUDIO_ERROR = "GET_MEDIA_AUDIO_ERROR";
export const CREATE_MEDIA_AUDIO = "CREATE_MEDIA_AUDIO ";
export const CREATE_MEDIA_AUDIO_ERROR = "CREATE_MEDIA_AUDIO_ERROR";
export const DELETE_MEDIA_AUDIO = "DELETE_MEDIA_AUDIO";
export const DELETE_MEDIA_AUDIO_ERROR = "DELETE_MEDIA_AUDIO_ERROR";
export const UPDATE_MEDIA_AUDIO = "UPDATE_MEDIA_AUDIO";
export const UPDATE_MEDIA_AUDIO_ERROR = "UPDATE_MEDIA_AUDIO_ERROR";
export const GET_CATEGORY_AUDIO = "GET_CATEGORY_AUDIO";
export const GET_CATEGORY_AUDIO_ERROR = "GET_CATEGORY_AUDIO_ERROR";
export const CREATE_ALBUM_MEDIA_AUDIO = "CREATE_ALBUM_MEDIA_AUDIO ";
export const CREATE_ALBUM_MEDIA_AUDIO_ERROR = "CREATE_ALBUM_MEDIA_AUDIO_ERROR ";
export const GET_ALBUM_MEDIA_AUDIO = "GET_ALBUM_MEDIA_AUDIO";
export const GET_ALBUM_MEDIA_AUDIO_ERROR = "GET_ALBUM_MEDIA_AUDIO_ERROR ";
export const GET_AUDIO_ALBUM_ID = "GET_AUDIO_ALBUM_ID";
export const GET_AUDIO_ALBUM_ID_ERROR = "GET_AUDIO_ALBUM_ID_ERROR";
export const UPDATE_ALBUM_MEDIA_AUDIO = "UPDATE_ALBUM_MEDIA_AUDIO";
export const UPDATE_ALBUM_MEDIA_AUDIO_ERROR = "UPDATE_ALBUM_MEDIA_AUDIO_ERROR";
export const DELETE_ALBUM_MEDIA_AUDIO = "DELETE_ALBUM_MEDIA_AUDIO";
export const DELETE_ALBUM_MEDIA_AUDIO_ERROR = "DELETE_ALBUM_MEDIA_AUDIO_ERROR";
export const DELETE_AUDIO_ALBUM_ID = "DELETE_AUDIO_ALBUM_ID";
export const DELETE_AUDIO_ALBUM_ID_ERROR = "DELETE_AUDIO_ALBUM_ID_ERROR";
// magazines
export const GET_MAGAZINES = "GET_MAGAZINES";
export const GET_MAGAZINES_ERROR = "GET_MAGAZINES_ERROR";
export const CREATE_MAGAZINES = "CREATE_MAGAZINES";
export const CREATE_MAGAZINES_ERROR = "CREATE_MAGAZINES_ERROR";
export const UPDATE_MAGAZINES = "UPDATE_MAGAZINES";
export const UPDATE_MAGAZINES_ERROR = "UPDATE_MAGAZINES_ERROR";
export const DELETE_MAGAZINES = "DELETE_MAGAZINES";
export const DELETE_MAGAZINES_ERROR = "DELETE_MAGAZINES_ERROR";
// amazing moment
export const GET_AMAZING_MOMENT = "GET_AMAZING_MOMENT";
export const GET_AMAZING_MOMENT_ERROR = "GET_AMAZING_MOMENT_ERROR";
export const CREATE_AMAZING_MOMENT = "CREATE_AMAZING_MOMENT";
export const CREATE_AMAZING_MOMENT_ERROR = "CREATE_AMAZING_MOMENT_ERROR";
export const DELETE_AMAZING_MOMENT = "DELETE_AMAZING_MOMENT";
export const DELETE_AMAZING_MOMENT_ERROR = "DELETE_AMAZING_MOMENT_ERROR";
// news and Updats
export const GET_NEWS = "GET_NEWS";
export const GET_NEWS_ERROR = "GET_NEWS_ERROR get_news_error";
export const CREATE_NEWS = "CREATE_NEWS";
export const CREATE_NEWS_ERROR = "CREATE_NEWS_ERROR";
export const UPDATE_NEWS = "UPDATE_NEWS";
export const UPDATES_NEWS_ERROR = "UPDATES_NEWS_ERROR";
export const DELETE_NEWS = "DELETE_NEWS";
export const DELETE_NEWS_ERROR = "DELETE_NEWS_ERROR";
//events

export const GET_ALL_LOCATIONS = "GET_ALL_LOCATIONS";
export const GET_EVENTS = "GET_EVENTS";
export const GET_EVENTS_ERROR = "GET_EVENTS_ERROR";
export const CREATE_EVENTS = "CREATE_EVENTS";
export const CREATE_EVENTS_ERROR = "CREATE_EVENTS_ERROR";
export const UPDATE_EVENTS = "UPDATE_EVENTS";
export const UPDATE_EVENTS_ERROR = "UPDATE_EVENTS_ERROR";
export const DELETE_EVENTS = "DELETE_EVENTS";
export const DELETE_EVENTS_ERROR = "DELETE_EVENTS_ERROR";
// content banner
export const GET_BANNER = "GET_BANNER";
export const GET_BANNER_ERROR = "GET_BANNER_ERROR";
export const CREATE_BANNER = "CREATE_BANNER";
export const CREATE_BANNER_ERROR = "CREATE_BANNER_ERROR";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const UPDATE_BANNER_ERROR = "UPDATE_BANNER_ERROR";
export const DELETE_BANNER = "DELETE_BANNER";
export const DELETE_BANNER_ERROR = "DELETE_BANNER_ERROR";
// advertisement
export const GET_ADVERTISEMENT = "GET_ADVERTISEMENT";
export const GET_ADVERTISEMENT_ERROR = "GET_ADVERTISEMENT_ERROR";
export const CREATE_ADVERTISEMENT = "CREATE_ADVERTISEMENT";
export const CREATE_ADVERTISEMENT_ERROR = "CREATE_ADVERTISEMENT_ERROR";
export const UPDATE_ADVERTISEMENT = "UPDATE_ADVERTISEMENT";
export const UPDATE_ADVERTISEMENT_ERROR = "UPDATE_ADVERTISEMENT_ERROR";
export const DELETE_ADVERTISEMENT = "DELETE_ADVERTISEMENT";
export const DELETE_ADVERTISEMENT_ERROR = "DELETE_ADVERTISEMENT_ERROR";
// gamification
export const CREATE_GAMIFICATION = "CREATE_GAMIFICATION";
export const CREATE_GAMIFICATION_ERROR = "CREATE_GAMIFICATION_ERROR";
export const GET_GAMIFICATION = "GET_GAMIFICATION";
export const GET_GAMIFICATION_ERROR = "GET_GAMIFICATION_ERROR";
export const DELETE_GAMIFICATION = "DELETE_GAMIFICATION";
export const DELETE_GAMIFICATION_ERROR = "DELETE_GAMIFICATION_ERROR";
export const UPDATE_GAMIFICATION_VIDEO = "UPDATE_GAMIFICATION_VIDEO";
export const UPDATE_GAMIFICATION_Video_ERROR =
    "UPDATE_GAMIFICATION_Video_ERROR";
// Gamifiation Series
export const CREATE_GAMI_SERIES = "CREATE_GAMI_SERIES";
export const CREATE_GAMI_SERIES_ERROR = "CREATE_GAMI_SERIES_ERROR";
export const GET_GAMI_SERIES_ID = "GET_GAMI_SERIES_ID";
export const GET_GAMI_SERIES_ID_ERROR = "GET_GAMI_SERIES_ID_ERROR";
export const DELETE_GAMI_VIDEO_SERIES_CHILD = "DELETE_GAMI_VIDEO_SERIES_CHILD";
export const DELETE_GAMI_VIDEO_SERIES_CHILD_ERROR =
    "DELETE_GAMI_VIDEO_SERIES_CHILD_ERROR";
export const GET_GAMI_SERIES = "GET_GAMI_SERIES";
export const GET_GAMI_SERIES_ERROR = "GET_GAMI_SERIES_ERROR";
export const UPDATE_GAMI_SERIES = "UPDATE_GAMI_SERIES";
export const UPDATE_GAMI_SERIES_ERROR = "UPDATE_GAMI_SERIES_ERROR";
export const DELETE_GAMI_SERIES = "DELETE_GAMI_SERIES";
export const DELETE_GAMI_SERIES_ERROR = "DELETE_GAMI_SERIES_ERROR";
// gamification Quiz
export const CREATE_GAMIFICATION_QUIZ = "CREATE_GAMIFICATION_QUIZ";
export const CREATE_GAMIFICATION_QUIZ_ERROR = "CREATE_GAMIFICATION_QUIZ_ERROR";
export const GET_GAMIFICATION_QUIZ = "GET_GAMIFICATION_QUIZ";
export const GET_GAMIFICATION_QUIZ_ERROR = "GET_GAMIFICATION_QUIZ_ERROR";
export const DELETE_GAMIFICATION_QUIZ = "DELETE_GAMIFICATION_QUIZ";
export const DELETE_GAMIFICATION_QUIZ_ERROR = "DELETE_GAMIFICATION_QUIZ_ERROR";
export const UPDATE_GAMIFICATION_QUIZ = "UPDATE_GAMIFICATION_QUIZ";
export const UPDATE_GAMIFICATION_QUIZ_ERROR = "UPDATE_GAMIFICATION_QUIZ_ERROR";
// gamification Quiz Question
export const CREATE_GAMIFICATION_QUIZ_QUES = "CREATE_GAMIFICATION_QUIZ_QUES";
export const CREATE_GAMIFICATION_QUIZ_QUES_ERROR =
    "CREATE_GAMIFICATION_QUIZ_QUES_ERROR";
export const GET_GAMIFICATION_QUIZ_QUES = "GET_GAMIFICATION_QUIZ_QUES";
export const GET_GAMIFICATION_QUIZ_QUES_ERROR =
    "GET_GAMIFICATION_QUIZ_QUES_ERROR";
export const DELETE_GAMIFICATION_QUIZ_QUES = "DELETE_GAMIFICATION_QUIZ_QUES";
export const DELETE_GAMIFICATION_QUIZ_QUES_ERROR =
    "DELETE_GAMIFICATION_QUIZ_QUES_ERROR";
export const UPDATE_GAMIFICATION_QUIZ_QUES = "UPDATE_GAMIFICATION_QUIZ_QUES";
export const UPDATE_GAMIFICATION_QUIZ_QUES_ERROR =
    "UPDATE_GAMIFICATION_QUIZ_QUES_ERROR";
//short media Video
export const GET_SHORT_VIDEO = "GET_SHORT_VIDEO get_short_video";
export const GET_SHORT_VIDEO_ERROR =
    "GET_SHORT_VIDEO_ERROR get_short_video_error";
export const UPDATE_SHORT_VIDEO = "UPDATE_SHORT_VIDEO update_short_video";
export const UPDATE_SHORT_VIDEO_ERROR =
    "UPDATE_SHORT_VIDEO_ERROR update_short_video_error";
export const CREATE_SHORT_VIDEO = "CREATE_SHORT_VIDEO create_short_video";
export const CREATE_SHORT_VIDEO_ERROR =
    "CREATE_SHORT_VIDEO_ERROR create_short_video_error";
export const DELETE_SHORT_VIDEO = "DELETE_SHORT_VIDEO";
export const DELETE_SHORT_VIDEO_ERROR = "DELETE_SHORT_VIDEO_ERROR";
// product
export const GET_PRODUCT = "GET_PRODUCT get_product";
export const GET_PRODUCT_ERROR = "GET_PRODUCT_ERROR get_product_error";
export const UPDATE_PRODUCT = "UPDATE_PRODUCT update_product";
export const UPDATE_PRODUCT_ERROR = "UPDATE_PRODUCT_ERROR update_product_error";
export const CREATE_PRODUCT = "CREATE_PRODUCT create_product";
export const CREATE_PRODUCT_ERROR = "CREATE_PRODUCT_ERROR";
export const DELETE_PRODUCT = "DELETE_PRODUCT";
export const DELETE_PRODUCT_ERROR = "DELETE_PRODUCT_ERROR";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS";
// Add Category
export const GET_CATEGORY = "GET_CATEGORY get_category";
export const GET_CATEGORY_ERROR = "GET_CATEGORY_ERROR get_category_error";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY update_category";
export const UPDATE_CATEGORY_ERROR =
    "UPDATE_CATEGORY_ERROR update_category_error";
export const CREATE_CATEGORY = "CREATE_CATEGORY create_category";
export const CREATE_CATEGORY_ERROR = "CREATE_CATEGORY_ERROR";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const DELETE_CATEGORY_ERROR = "DELETE_CATEGORY_ERROR";
// Add Author
export const GET_AUTHOR = "GET_AUTHOR get_author";
export const GET_AUTHOR_ERROR = "GET_AUTHOR_ERROR get_author_error";
export const UPDATE_AUTHOR = "UPDATE_AUTHOR update_author";
export const UPDATE_AUTHOR_ERROR = "UPDATE_AUTHOR_ERROR update_author_error";
export const CREATE_AUTHOR = "CREATE_AUTHOR create_author";
export const CREATE_AUTHOR_ERROR = "CREATE_AUTHOR_ERROR";
export const DELETE_AUTHOR = "DELETE_AUTHOR";
export const DELETE_AUTHOR_ERROR = "DELETE_AUTHOR_ERROR";
// age category
export const GET_AGE_CATEGORY = "GET_AGE_CATEGORY get_AGE_CATEGORY";
export const GET_AGE_CATEGORY_ERROR =
    "GET_AGE_CATEGORY_ERROR get_AGE_CATEGORY_error";
export const UPDATE_AGE_CATEGORY = "UPDATE_AGE_CATEGORY update_AGE_CATEGORY";
export const UPDATE_AGE_CATEGORY_ERROR =
    "UPDATE_AGE_CATEGORY_ERROR update_AGE_CATEGORY_error";
export const CREATE_AGE_CATEGORY = "CREATE_AGE_CATEGORY create_AGE_CATEGORY";
export const CREATE_AGE_CATEGORY_ERROR = "CREATE_AGE_CATEGORY_ERROR";
export const DELETE_AGE_CATEGORY = "DELETE_AGE_CATEGORY";
export const DELETE_AGE_CATEGORY_ERROR = "DELETE_AGE_CATEGORY_ERROR";
// premium data plan
export const GET_PREMIUM = "GET_PREMIUM get_PREMIUM";
export const GET_PREMIUM_ERROR = "GET_PREMIUM_ERROR get_PREMIUM_error";
export const UPDATE_PREMIUM = "UPDATE_PREMIUM update_PREMIUM";
export const UPDATE_PREMIUM_ERROR = "UPDATE_PREMIUM_ERROR update_PREMIUM_error";
export const CREATE_PREMIUM = "CREATE_PREMIUM create_PREMIUM";
export const CREATE_PREMIUM_ERROR = "CREATE_PREMIUM_ERROR";
export const DELETE_PREMIUM = "DELETE_PREMIUM";
export const DELETE_PREMIUM_ERROR = "DELETE_PREMIUM_ERROR";
//events
export const GET_EVENTS_BANNER = "GET_EVENTS_BANNER";
export const GET_EVENTS_BANNER_ERROR = "GET_EVENTS_BANNER_ERROR";
export const CREATE_EVENTS_BANNER = "CREATE_EVENTS_BANNER";
export const CREATE_EVENTS_BANNER_ERROR = "CREATE_EVENTS_BANNER_ERROR";
export const UPDATE_EVENTS_BANNER = "UPDATE_EVENTS_BANNER";
export const UPDATE_EVENTS_BANNER_ERROR = "UPDATE_EVENTS_BANNER_ERROR";
export const DELETE_EVENTS_BANNER = "DELETE_EVENTS_BANNER";
export const DELETE_EVENTS_BANNER_ERROR = "DELETE_EVENTS_BANNER_ERROR";
//Description
export const GET_EVENTS_DESC = "GET_EVENTS_DESC";
export const GET_EVENTS_DESC_ERROR = "GET_EVENTS_DESC_ERROR";
export const CREATE_EVENTS_DESC = "CREATE_EVENTS_DESC";
export const CREATE_EVENTS_DESC_ERROR = "CREATE_EVENTS_DESC_ERROR";
export const UPDATE_EVENTS_DESC = "UPDATE_EVENTS_DESC";
export const UPDATE_EVENTS_DESC_ERROR = "UPDATE_EVENTS_DESC_ERROR";
export const DELETE_EVENTS_DESC = "DELETE_EVENTS_DESC";
export const DELETE_EVENTS_DESC_ERROR = "DELETE_EVENTS_DESC_ERROR";
//Testinomial
export const GET_EVENTS_TEST = "GET_EVENTS_TEST";
export const GET_EVENTS_TEST_ERROR = "GET_EVENTS_TEST_ERROR";
export const CREATE_EVENTS_TEST = "CREATE_EVENTS_TEST";
export const CREATE_EVENTS_TEST_ERROR = "CREATE_EVENTS_TEST_ERROR";
export const UPDATE_EVENTS_TEST = "UPDATE_EVENTS_TEST";
export const UPDATE_EVENTS_TEST_ERROR = "UPDATE_EVENTS_TEST_ERROR";
export const DELETE_EVENTS_TEST = "DELETE_EVENTS_TEST";
export const DELETE_EVENTS_TEST_ERROR = "DELETE_EVENTS_TEST_ERROR";
//Sneak Peak
export const GET_EVENTS_SNEAK = "GET_EVENTS_SNEAK";
export const GET_EVENTS_SNEAK_ERROR = "GET_EVENTS_SNEAK_ERROR";
export const CREATE_EVENTS_SNEAK = "CREATE_EVENTS_SNEAK";
export const CREATE_EVENTS_SNEAK_ERROR = "CREATE_EVENTS_SNEAK_ERROR";
export const UPDATE_EVENTS_SNEAK = "UPDATE_EVENTS_SNEAK";
export const UPDATE_EVENTS_SNEAK_ERROR = "UPDATE_EVENTS_SNEAK_ERROR";
export const DELETE_EVENTS_SNEAK = "DELETE_EVENTS_SNEAK";
export const DELETE_EVENTS_SNEAK_ERROR = "DELETE_EVENTS_SNEAK_ERROR";
//Event Category
export const GET_EVENTS_CATEGORY = "GET_EVENTS_CATEGORY";
export const GET_EVENTS_CATEGORY_ERROR = "GET_EVENTS_CATEGORY_ERROR";
export const CREATE_EVENTS_CATEGORY = "CREATE_EVENTS_CATEGORY";
export const CREATE_EVENTS_CATEGORY_ERROR = "CREATE_EVENTS_CATEGORY_ERROR";
export const UPDATE_EVENTS_CATEGORY = "UPDATE_EVENTS_CATEGORY";
export const UPDATE_EVENTS_CATEGORY_ERROR = "UPDATE_EVENTS_CATEGORY_ERROR";
export const DELETE_EVENTS_CATEGORY = "DELETE_EVENTS_CATEGORY";
export const DELETE_EVENTS_CATEGORY_ERROR = "DELETE_EVENTS_CATEGORY_ERROR";
//Event Faqs
export const GET_EVENTS_FAQS = "GET_EVENTS_FAQS";
export const GET_EVENTS_FAQS_ERROR = "GET_EVENTS_FAQS_ERROR";
export const CREATE_EVENTS_FAQS = "CREATE_EVENTS_FAQS";
export const CREATE_EVENTS_FAQS_ERROR = "CREATE_EVENTS_FAQS_ERROR";
export const UPDATE_EVENTS_FAQS = "UPDATE_EVENTS_FAQS";
export const UPDATE_EVENTS_FAQS_ERROR = "UPDATE_EVENTS_FAQS_ERROR";
export const DELETE_EVENTS_FAQS = "DELETE_EVENTS_FAQS";
export const DELETE_EVENTS_FAQS_ERROR = "DELETE_EVENTS_FAQS_ERROR";
//Event Session
export const GET_EVENTS_SESSION = "GET_EVENTS_SESSION";
export const GET_EVENTS_SESSION_ERROR = "GET_EVENTS_SESSION_ERROR";
export const CREATE_EVENTS_SESSION = "CREATE_EVENTS_SESSION";
export const CREATE_EVENTS_SESSION_ERROR = "CREATE_EVENTS_SESSION_ERROR";
export const UPDATE_EVENTS_SESSION = "UPDATE_EVENTS_SESSION";
export const UPDATE_EVENTS_SESSION_ERROR = "UPDATE_EVENTS_SESSION_ERROR";
export const DELETE_EVENTS_SESSION = "DELETE_EVENTS_SESSION";
export const DELETE_EVENTS_SESSION_ERROR = "DELETE_EVENTS_SESSION_ERROR";
//Events By ID
export const GET_EVENTS_ID = "GET_EVENTS_ID";
export const GET_EVENTS_ID_ERROR = "GET_EVENTS_ID_ERROR";
//Get In Touch
export const GET_IN_TOUCH = "GET_IN_TOUCH";
export const GET_IN_TOUCH_ERROR = "GET_IN_TOUCH_ERROR";
//Video Series
export const GET_VIDEO_SERIES = "GET_VIDEO_SERIES";
export const GET_VIDEO_SERIES_ERROR = "GET_VIDEO_SERIES_ERROR";
export const DELETE_VIDEO_SERIES = "DELETE_VIDEO_SERIES";
export const DELETE_VIDEO_SERIES_CHILD = "DELETE_VIDEO_SERIES_CHILD";
export const DELETE_VIDEO_SERIES_ERROR = "DELETE_VIDEO_SERIES_ERROR";
//VideoSeries By ID
export const GET_VIDEO_SERIES_ID = "GET_VIDEO_SERIES_ID";
export const GET_VIDEO_SERIES__ID_ERROR = "GET_VIDEO_SERIES__ID_ERROR";

// HEADER MENU
export const GET_ALL_HEADER_MENU = "GET_ALL_HEADER_MENU";
export const CREATE_HEADER_MENU = "CREATE_HEADER_MENU";

// INTROSPECTION

export const CREATE_INTROSPECTION = "CREATE_INTROSPECTION";
export const DELETE_INTROSPECTION = "DELETE_INTROSPECTION";
export const GET_INTROSPECTION = "GET_INTROSPECTION";
export const UPDATE_INTROSPECTION = "UPDATE_INTROSPECTION";
export const CREATE_INTROSPECTION_QUESTION = "CREATE_INTROSPECTION_QUESTION";
export const DELETE_INTROSPECTION_QUESTION = "DELETE_INTROSPECTION_QUESTION";
export const GET_INTROSPECTION_QUESTION = "GET_INTROSPECTION_QUESTION";
export const UPDATE_INTROSPECTION_QUESTION = "UPDATE_INTROSPECTION_QUESTION";
export const GET_INTROSPECTION_RESULT = "GET_INTROSPECTION_RESULT";
