export const authRole = {
    admin: ["admin"],
    user: ["user", "admin"],
};

export const RoutePermittedRole = {
    admin: "admin",
    user: "user",
};
export const defaultUser = {
    displayName: "John Alex",
    email: "demo@example.com",
    token: "access-token",
    role: "user",
    photoURL: "/assets/images/avatar/A11.jpg",
};
// add static Author Name
export const memberCategory = [
    { label: "Satsangi", value: "satsangi" },
    { label: "Non Satsangi", value: "nonSatsangi" },
    { label: "All", value: "all" },
];
export const FILE_LANGUAGE_OPTIONS = [
    { name: "Hindi" },
    { name: "English" },
    { name: "Odia" },
    { name: "Bengali" },
];
export const SUPPORTED_FORMAT = [
    "image/jpg",
    "image/jpeg",
    "image/png",
    "image/apng",
    "image/gif",
    "image/svg+xml",
    "image/webp",
];
export const SUPPORTED_EXCEL_FORMAT = [
    ".xlsx",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ".xls",
];
export const SUPPORTED_FORMAT_PDF = ["application/pdf"];
export const SUPPORTED_FORMAT_VIDEO = [
    "video/AVI",
    "video/MP4",
    "video/MOV",
    "video/mp4",
    "video/webm",
];
export const SUPPORTED_FORMAT_AUDIO = [
    "audio/mpeg",
    "audio/MP3",
    "audio/mp3",
    "audio/WAV",
    "audio/OGG",
];
export const orderStatusList = [
    { label: "Pending", value: "pending" },
    { label: "On the way", value: "ON_THE_WAY" },
    { label: "Delivered", value: "DELIVERED" },
];

export const AVAILABLE_INPUT_TYPES = [
    "TEXT",
    "RADIO",
    "CHECKBOX",
    "DROPDOWN",
    "DATE",
    "RATING",
    "MULTIPLE_CHOICE_GRID",
];
export const TOAST_TIMEOUT = 2000;

// ===========================
// LOCAL
// ===========================
// export const baseURL = "http://192.168.80.61:8766";
// export const baseURLAuth = "http://192.168.80.31:8766/api/auth";

// ===========================
// DEVELOPMENT
// ===========================
// export const baseURL = "http://3.111.162.16:8762/api/admin";
// export const baseURLAuth = "http://3.111.162.16:8762/api/auth";

// ===========================
// PRODUCTION
// ===========================
export const baseURL = "https://admin.bgsm.org/api/admin";
export const baseURLAuth = "https://admin.bgsm.org/api/auth";
// export const baseURL = "https://seva.bgsm.org/api/admin";
// export const baseURLAuth = "https://seva.bgsm.org/api/auth";

export const initialUrl = "/subscription/plans"; // this url will open after login
export const regMatch =
    "/^((http|https)://)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(.[a-zA-Z]+)+(/)?.([w?[a-zA-Z-_%/@?]+)*([^/w?[a-zA-Z0-9_-]+=w+(&[a-zA-Z0-9_]+=w+)*)?$/";
